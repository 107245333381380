import { getPublicEnv } from '../environment/getPublicEnv';

/** Sets the base url of the API automatically */
export class ApiURL extends URL {
  constructor(arg: ConstructorParameters<typeof URL>[0]) {
    if (typeof arg === 'string') {
      super(arg.startsWith('/') ? getPublicEnv('PUBLIC_API_URL') + arg : arg);
      return;
    }
    super(arg);
  }
}
