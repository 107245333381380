import { ApiError } from './ApiError';
import { ApiURL } from './ApiURL';

interface FetchAPIOptions extends RequestInit {
  accessToken?: string;
}

export async function fetchAPI(path: string | ApiURL, options?: FetchAPIOptions): Promise<Response> {
  const { accessToken, ...fetchOptions } = options || {};

  const url = new ApiURL(path);
  const headers = new Headers(fetchOptions.headers);

  if (accessToken) {
    headers.set('Authorization', `Bearer ${accessToken}`);
  }

  const res = await fetch(url, { ...fetchOptions, headers });

  if (!res.ok) {
    let json: unknown;
    try {
      json = await res.json();
    } catch (_err) {
      // handled below
    }
    throw ApiError.fromResponse(res, json);
  }

  return res;
}
